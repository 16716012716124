<template>
	<div class="all-data-box">
		<div class="table-box scroll-style">
			<el-table :data="tableData" border stripe style="width: 100%" height="100%">
				<el-table-column type="index" fixed label="序号" width="60" align="center" :index="indexMethod"></el-table-column>
				<el-table-column prop="typeName" fixed label="设备名称" width="130" align="center"></el-table-column>
				<el-table-column prop="imei" fixed label="设备编号" width="160" align="center"></el-table-column>
				<el-table-column prop="cdate" fixed label="时间" width="160" align="center"></el-table-column>
				<el-table-column prop="voltage" fixed label="电压(V)" width="80"></el-table-column>
				<el-table-column v-for="(i, index) in channelList" :key="index" :prop="i.val" :label="i.label" width="68"></el-table-column>
			</el-table>
		</div>
		<div class="pagination-box">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page"
				:page-sizes="[30, 50, 100, 300]"
				:page-size="size"
				layout="sizes, prev, pager, next, jumper, total"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			list: [], //列表原始数据
			tableData: [], //列表显示数据
			channelList: [], // 列表通道数据
			boxType: 1,
			page: 1,
			size: 100,
			total: 0
		};
	},
	created() {
		for (let i = 0; i < 32; i++) {
			let num = i + 1;
			this.channelList.push({
				label: '通道' + num,
				val: 'channel' + num
			});
		}
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取数据
		getList(data) {
			// console.log(data)
			this.list = [];
			this.total = data.length;
			this.page = 1;
			this.size = 100;
			data.forEach(i => {
				i.vs.forEach((item, ind) => {
					let num = ind + 1;
					i['channel' + num] = item;
				});
				this.list.push(i);
			});
			this.dataCutting();
		},
		// 列表数据前端分页
		dataCutting() {
			this.tableData = [];
			let len = this.list.length;
			let min = (this.page - 1) * this.size;
			let max = (this.page * this.size) <= len ? (this.page * this.size) : len;
			for (let i = min; i < max; i++) {
				let item = this.list[i];
				this.tableData.push(item)
			}
		},
		// 序号格式化
		indexMethod(index){
			return index + (this.page - 1) * this.size + 1;
		},
		// 每页条数变化
		handleSizeChange(val) {
			this.page = 1;
			this.size = val;
			this.dataCutting();
		},
		// 页码变化
		handleCurrentChange(val) {
			this.page = val;
			this.dataCutting();
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.all-data-box {
	width: 100%;
	height: 100%;

	.table-box {
		width: 100%;
		height: calc(100% - 50px);
		margin-bottom: 15px;

		::v-deep {
			.el-table__fixed {
				height: calc(100% - 8px) !important;
				background: #fff;
			}
		}
	}
	
	.pagination-box{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
</style>
