<template>
	<!-- 裂缝计 2-->
	<div class="crackMeter-box">
		<div class="table-box" v-show="boxType == 1">
			<el-table :data="list" border stripe style="width: 100%" height="100%">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				<el-table-column prop="time" label="时间" width="150"></el-table-column>
				<el-table-column prop="v" label="裂缝宽度" width="80"></el-table-column>
				<el-table-column prop="v1" label="当前变化量" width="100"></el-table-column>
				<el-table-column prop="v2" label="累计变化量" width="100"></el-table-column>
			</el-table>
		</div>
		<div class="graph-box" v-show="boxType == 2">
			<div class="echarts-box" id="crackMetergGraph"></div>
			<div class="echarts-box" id="crackMetergGraph1"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'crackMeter',
	data() {
		return {
			list: [], //列表数据
			boxType: 1,
			myChart: null, //echarts的DOM对象
			myChart1: null, //echarts的DOM对象
			option: {
				title: {
					text: '折线图堆叠'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: ['Mon']
				},
				grid: {
					left: '2%',
					top: 60,
					bottom: 60,
					right: '2%'
				},
				xAxis: {
					type: 'category',
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Mon',
						type: 'line',
						stack: '总量',
						data: [150, 230, 224, 218, 135, 147, 260]
					}
				]
			} //折线图配置参数
		};
	},
	created() {},
	mounted() {
		// var chartDom = document.getElementById('crackMetergGraph');
		// this.myChart = this.echarts.init(chartDom);
		// var chartDom1 = document.getElementById('crackMetergGraph1');
		// this.myChart1 = this.echarts.init(chartDom1);
	},
	computed: {},
	methods: {
		// 获取数据
		getList(data) {
			this.list = data;
		},
		// 曲线图绘制
		drawGraph() {
			var option = this.deepCopy(this.option);
			option && this.myChart.setOption(option);
			this.myChart.resize();
			var option1 = this.deepCopy(this.option);
			option1 && this.myChart1.setOption(option1);
			this.myChart1.resize();
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.crackMeter-box {
	width: 100%;
	height: 100%;
	background-color: #ffffff;

	// 数据表
	.table-box {
		width: 100%;
		height: 100%;
	}

	// 折线图
	.graph-box {
		width: 100%;
		height: 100%;

		.echarts-box {
			width: 100%;
			height: 400px;

			&div {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
