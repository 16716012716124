<template>
	<!-- 变形数据 -->
	<div class="deformation-page">
		<div class="header">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				<el-menu-item index="1" v-if="judgment('authorityList', 308, that)">曲线图</el-menu-item>
				<el-menu-item index="2">测点数据</el-menu-item>
			</el-menu>
			<!-- 曲线图 -->
			<div class="right-box chart-screen" v-show="activeIndex == '1'">
				<div class="screen selection">
					<el-select v-model="chartPoint" multiple collapse-tags filterable :multiple-limit="10" placeholder="请选择测点" @change="chartChange">
						<el-option v-for="(item, index) in checkedList" :key="index" :label="item.name" :value="item.id">
							<!-- :hide-after="300" -->
							<el-tooltip effect="light" :content="item.address" placement="right" :enterable="false">
								<span>{{ item.name }}</span>
							</el-tooltip>
						</el-option>
					</el-select>
				</div>
				<div class="screen time">
					<span>时段：</span>
					<el-date-picker
						popper-class="chart-time"
						v-model="chartForm.timeS"
						type="datetime"
						:clearable="false"
						value-format="yyyy-MM-dd HH"
						format="yyyy-MM-dd HH"
						placeholder="选择日期时间"
					></el-date-picker>
					<span>至</span>
					<el-date-picker
						popper-class="chart-time"
						v-model="chartForm.timeE"
						type="datetime"
						:clearable="false"
						value-format="yyyy-MM-dd HH"
						format="yyyy-MM-dd HH"
						placeholder="选择日期时间"
					></el-date-picker>
				</div>
				<el-button type="primary" size="small" @click="chartQuery">查询</el-button>
			</div>
			<!-- 变形测点数据 -->
			<div class="right-box deformation-screen" v-show="activeIndex == '2'">
				<!-- <div class="screen selection">
					<el-select v-model="instrument" clearable placeholder="请选择参数" @change="resetData(dataType)">
						<el-option v-for="item in instrumentOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div> -->

				<!-- <div class="screen selection">
					<el-select v-if="dataType == 2" v-model="pointGroup" :disabled="instrument == null" multiple collapse-tags clearable placeholder="请选择测点">
						<el-option v-for="item in pointGroupOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
					</el-select>
					<el-select v-else v-model="pointGroup" :disabled="instrument == null" clearable placeholder="请选择测点">
						<el-option v-for="item in pointGroupOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
					</el-select>
				</div> -->
				<div class="screen selection">
					<el-select v-model="sensorType" clearable placeholder="请选择传感器类型">
						<el-option v-for="item in sensorTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="screen selection">
					<el-select v-model="equipmentSelect" clearable placeholder="请选择设备">
						<el-option v-for="item in equipmentOptions" :key="item.id" :label="item.imei" :value="item.imei"></el-option>
					</el-select>
				</div>
				<div class="screen time">
					<el-date-picker
						v-model="times"
						type="date"
						placeholder="选择日期"
						value-format="yyyy-MM-dd"
					></el-date-picker>
				</div>
				<div class="select-box but"><el-button size="small" type="primary" @click="inquireData">查询</el-button></div>
			</div>
		</div>

		<div class="child-page">
			<!-- 曲线图 -->
			<chart ref="chart" v-show="activeIndex === '1'" :authorityList="authorityList"></chart>
			<!-- 变形测点数据 -->
			<deformation ref="deformation" v-show="activeIndex === '2'" :pageType="instrument" :authorityList="authorityList"></deformation>
		</div>
	</div>
</template>

<script>
import chart from './chart/chart.vue';
import deformation from './deformationData/deformation.vue';
export default {
	name: '',
	data() {
		return {
			activeIndex: '2',
			chartPoint: [], // 曲线图选择测点id
			chartForm: {
				timeS: null,
				timeE: null
			}, //曲线图查询时间
			checkedList: [], //曲线图搜索多选框列表数据
			instrumentOptions: [
				{
					name: '全部',
					id: 0
				},
				{
					name: '静力水准仪',
					id: 1
				},
				{
					name: '倾角计',
					id: 2
				},
				{
					name: '裂缝计',
					id: 3
				},
				{
					name: '激光测距仪',
					id: 4
				}
			], // 仪器选择列表
			sensorTypeOptions: [
				// {
				// 	name: '振弦传感器',
				// 	id: 1
				// },
				{
					name: '激光测距仪',
					id: 2
				},
				{
					name: '倾角计',
					id: 3
				},
				{
					name: '裂缝计',
					id: 4
				},
				{
					name: '静力水准仪',
					id: 5
				},
				{
					name: '混合模式',
					id: 6
				}
			], // 传感器类型列表
			sensorType: '',//选择的传感器类型
			instrument: 0, // 选择的仪器
			pageType: null, // 展示页面类型
			dataType: 1, //数据展示类型
			pointGroupData: {}, // 测点选择数据
			// pointGroupOptions: [], // 测点组选择列表
			pointGroup: null, // 选择的测点
			equipmentSelect: '', // 选择的设备
			equipmentOptions: '', // 设备选择列表
			times: '', //时间
			authorityList: [], //权限数组
			that: null
		};
	},
	created() {
		this.that = this;
		this.query = this.$route.query;
		// this.getPermissions();
		this.getequipmentList();
		// this.setTime();
		// this.getChartPointList();
		// this.getPointList();
	},
	mounted() {
		const D = new Date();
		let before = new Date(D.getTime() - 24 * 60 * 60 * 1000 * 7).format('yyyy-MM-dd') + ' 00:00:00';
		let now = new Date(D.getTime()).format('yyyy-MM-dd') + ' 23:59:59';
		this.chartForm = {
			timeS: new Date(D.getTime()).format('yyyy-MM-dd') + ' 00',
			timeE: new Date(D.getTime()).format('yyyy-MM-dd hh')
		};
		this.times = new Date(D.getTime()).format('yyyy-MM-dd');
		this.inquireData();
	},
	computed: {
		pointGroupOptions: function() {
			// 测点组选择列表
			if (this.instrument != null) {
				this.setTime();
				return this.pointGroupData[this.instrument];
			} else {
				return [];
			}
		}
	},
	methods: {
		// 获取页面权限
		getPermissions() {
			this.authorityList = [];
			this.axios.get('web2/bxtk/data/odnr/giyu').then(res => {
				if (res.status) {
					this.authorityList = res.data;
				}
			});
		},
		// 菜单切换
		handleSelect(key, keyPath) {
			// // console.log(key, keyPath);
			this.activeIndex = keyPath[0];
			if (key == 1) {
				setTimeout(() => {
					if (this.checkedList.length != 0) {
						this.chartPoint = [this.checkedList[0].id];
					}
					this.chartQuery();
				}, 300);
			} else if (key == 2) {
			}
		},
		// 获取曲线图测点列表
		getChartPointList() {
			var data = {
				id: this.query.id
			};
			this.axios.post('web2/data/odnr/gpname', data).then(res => {
				this.checkedList = [];
				if (res.status) {
					this.checkedList = res.data;
				}
			});
		},
		// 获取设备选择列表
		getequipmentList() {
			var data = {
				id: this.query.id
			};
			this.axios.post('web2/bxtk/data/odnr/gdnum', data).then(res => {
				this.equipmentOptions = [];
				if (res.status) {
					this.equipmentOptions = res.data;
				}
			});
		},
		// 曲线图测点选择
		chartChange() {},
		// 曲线图查询
		chartQuery() {
			if (this.chartPoint.length == 0) {
				this.$message({
					message: '请先选择测点',
					type: 'warning'
				});
				return;
			}
			var name = [];
			this.checkedList.forEach(val => {
				if (this.chartPoint.indexOf(val.id) != -1) {
					name.push(val.name);
				}
			});
			var data = [];
			this.chartPoint.forEach((i, index) => {
				data.push({
					id: i,
					begin: this.chartForm.timeS + ':00:00',
					end: this.chartForm.timeE + ':59:59',
					name: name[index]
				});
				// // console.log(data)
				// return
			});
			// // console.log(data)
			this.$refs.chart.getChartData(data);
		},
		// 获取变形数据测点列表
		getPointList() {
			var data = {
				projectId: 0
			};
			this.axios.post('dfmt/data/gdplist', data).then(res => {
				if (res.status) {
					this.pointGroupData = res.data;
				}
			});
		},
		// 变形数据查询
		inquireData() {
			let data = {
				id: this.query.id,
				begin: this.times + ' 00:00:00',
				end: this.times + ' 23:59:59',
				imei: this.equipmentSelect,
				dataType: this.dataType,
				type: this.sensorType
			};
			// if (!this.pointGroup) {
			// 	this.$message({
			// 		message: '请先选择测点',
			// 		type: 'warning'
			// 	});
			// 	return;
			// }
			this.$refs.deformation.queryData(data);
		},
		// 重置测点数据
		resetData(data) {
			this.pointGroup = null;
			if (data == 2) {
				this.pointGroup = [];
			} else {
				this.pointGroup = '';
			}
			this.inquireData();
		},
		// 时间赋值
		setTime() {
			var start = new Date();
			this.times = [new Date(start.getTime() - 7 * 24 * 60 * 60 * 1000).format('yyyyMMddhhmmss'), new Date().format('yyyyMMddhhmmss')];
		}
	},
	components: {
		chart,
		deformation
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.deformation-page {
	width: 100%;
	height: 100%;
	overflow: hidden;

	.header {
		padding: 0 15px 0 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		::v-deep {
			.el-menu-demo .el-menu-item {
				font-size: 17px;
				font-weight: 600;
				color: #303133;
				letter-spacing: 1px;
			}

			.el-menu.el-menu--horizontal {
				border-bottom: none;
			}
		}

		.right-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.time {
				> span {
					display: inline-block;
					margin: 0 5px;
				}

				::v-deep .el-range-editor {
					padding: 0 10px;
				}
			}

			::v-deep {
				.el-select__tags-text {
					max-width: 70px;
				}
			}
		}

		.chart-screen {
			.selection {
				::v-deep {
					.el-select {
						width: 220px !important;

						.el-input {
							width: 100% !important;
						}
					}
				}
			}
		}

		.screen {
			margin: 0 15px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			::v-deep {
				.el-input,
				.el-select {
					width: 180px;
					height: 32px;
				}

				.el-input__inner {
					padding-right: 0;
					height: 100% !important;
				}

				.el-input__icon {
					height: auto;
				}

				.el-input__suffix {
					top: -4px;
				}

				.el-input__prefix {
					top: -4px;
				}
			}

			span {
				font-size: 15px;
			}
		}

		.screen-cell {
			margin-left: 20px;
		}
	}

	.child-page {
		display: flex;
		width: 100%;
		height: calc(100% - 60px);
		// justify-content: ;
	}
}
</style>
