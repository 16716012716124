import data from "./deformationData.js";

import allData from "./child/allData.vue"; // 全部数据
import inclinometer from "./child/inclinometer.vue"; //倾角计
import staticLevel from "./child/staticLevel.vue"; //静力水平仪
import laserRangeFinder from "./child/laserRangeFinder.vue"; //激光测距仪
import crackMeter from "./child/crackMeter.vue"; //裂缝计

export default {
	name: "deformation",
	props: {
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		// 展示页面类型
		pageType: {
			type: [Number, String],
			default: 0
		}
	},
	data,
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// /dfmt/data
		// 查询页面数据
		queryData(value) {
			// console.log(value)
			var data = this.deepCopy(value);
			this.parameter = this.deepCopy(data);
			for (let k in data) {
				this.queryParameter[k] = data[k];
			}
			this.dataType = data.dataType;
			this.getList(this.queryParameter);
		},
		// gdplist 测点
		// gdata1 列表数据（type:类型  projectId:项目id  pointName:测点名 begin：开始时间  end：结束时间
		// gdata2 曲线图
		// 获取列表数据
		getList(val) {
			let data = {
				id: '',
				begin: '',
				end: '',
				imei: '',
				type: '',
			};
			for (let k in this.queryParameter) {
				if (data.hasOwnProperty(k)) {
					data[k] = this.queryParameter[k];
				}
			}
			this.list = [];
			this.axios.post('web2/bxtk/data/odnr/glist', data).then((res) => {
				this.list = [];
				if (res.status) {
					this.list = res.data;
					this.inquireData()
				}

			})
		},
		
		// 获取曲线图数据
		getCurve(value) {
			var data = this.deepCopy(value);
			this.parameter = this.deepCopy(data);
			for (let k in this.queryParameter) {
				if (data.hasOwnProperty(k)) {
					this.queryParameter[k] = data[k];
				}
			}
			this.axios.post('dfmt/data/gdata2', this.queryParameter).then((res) => {

			})
		},
		// 设置数据
		inquireData() {
			// // console.log(this.list)
			if (this.pageType === 0) {
				this.$refs.allData.boxType = this.dataType;
				if (this.dataType == 1) { 
					this.$refs.allData.getList(this.list);
				} else if (this.dataType == 2) {
					// setTimeout(() => {
					// 	this.$refs.allData.drawGraph();
					// }, 300)
				}

			} else if (this.pageType == 1) {
				this.$refs.staticLevel.boxType = this.dataType;

				if (this.dataType == 1) {
					this.$refs.staticLevel.getList(this.list);
				} else if (this.dataType == 2) {
					setTimeout(() => {
						this.$refs.staticLevel.drawGraph();
					}, 300)
				}

			} else if (this.pageType == 2) {
				this.$refs.inclinometer.boxType = this.dataType;

				if (this.dataType == 1) {
					this.$refs.inclinometer.getList(this.list);
				} else if (this.dataType == 2) {
					setTimeout(() => {
						this.$refs.inclinometer.drawGraph();
					}, 300)
				}
			} else if (this.pageType == 3) {
				this.$refs.crackMeter.boxType = this.dataType;

				if (this.dataType == 1) {
					this.$refs.crackMeter.getList(this.list);
				} else if (this.dataType == 2) {
					setTimeout(() => {
						this.$refs.crackMeter.drawGraph();
					}, 300)
				}
			} else if (this.pageType == 4) {
				this.$refs.laserRangeFinder.boxType = this.dataType;

				if (this.dataType == 1) {
					this.$refs.laserRangeFinder.getList(this.list);
				} else if (this.dataType == 2) {
					setTimeout(() => {
						this.$refs.laserRangeFinder.drawGraph();
					}, 300)
				}

			}
		},
	},
	components: {
		inclinometer,
		staticLevel,
		laserRangeFinder,
		crackMeter,
		allData
	},
	beforeDestroy() {},
	watch: {}
}
